import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet";
import CanvasJSReact from '@canvasjs/react-charts';
export class Summaries extends Component {
	static displayName = Summaries.name;
	constructor(props) {
		super(props);
		this.state = {
			summaryDataCount: [],
			summaryDataItems: [],
			summaryProgress: [],
			loading: true,
			itemsLoading: true,
			progressLoading: true,
			summaryDataComplex: [],
			complexloading: true
		};
	}

	componentDidUpdate() {
		document.getElementById(window.location.hash.replace('#', ''))
			?.scrollIntoView({ behavior: 'instant', block: 'start' });
	}

	componentDidMount() {
		this.populateSummariesData();
		this.populateSummariesDataByItem();
		this.populateSummariesDataByItemComplex();
		this.getmappingprogress();
	}



	static renderGeneLinks(genes) {
		if (genes !== undefined && genes !== null) { 
				const omims = genes.split(",");
				const omimlinks = omims.map((omim, index) => (
					<a className= "tableLinkList" key={index} href={"/disease#" + omim.split(':')[1].replace(/ /g, '')}>{omim.split(':')[0]}</a>
				));
				return omimlinks;
			}
	}

	static renderTopandHome() {
		return (
			<p align="center">[ <a href="/summaries#top">Top of Page</a> | <a href="/">Home Page</a> ]</p>
		)
	}

	static renderSummariesTableA(summary) {
		return (

			<div>

				{
					<table className="table table-bordered" aria-labelledby="tableLabel">
						<thead>
							<tr >
								<td colSpan="3" className="tableHeader" >
									<h5 className="tableHeader" id="a-genes">A. Number of Genes and Loci by Disease Category (One Disease per Gene/Locus)</h5>
								</td>
							</tr>
							<tr align="center">
								<th width="50%">Disease Category</th>
								<th width="25%">Total No. of Genes and Loci</th>
								<th width="25%">No. of Identified Genes</th>
							</tr>
						</thead>
						<tbody>
							{
								summary.map(genes => {
									return (
										<tr key={ genes.code }>
											<td align="left"> {genes.description}</td>
											<td align="center">{genes.geneAndLoci}</td>
											<td align="center">{genes.identifiedGenes}</td>
										</tr>
									)
								})
								}

								<tr className="tableTotals">
									<td>TOTALS</td>
									<td>{(summary.reduce((a, v) => a = a + v.geneAndLoci, 0))}</td>
									<td>{(summary.reduce((a, v) => a = a + v.identifiedGenes, 0))}</td>
								</tr>
						</tbody>
					</table>
				}
				{ Summaries.renderTopandHome() }
			</div>
		);
	}

	static renderListedTable(summary, title, tableId, colTitle1, colTitle2) {
	
		return (

			<div>
				{
					<table className="table table-bordered" aria-labelledby="tableLabel">
						<thead>
							<tr >
								<td colSpan="3" className="tableHeader" id={tableId}>
									<h5 id="a-genes">{title}</h5>
								</td>
							</tr>
							<tr align="center">
								<th width="35%">Disease Category</th>
								<th width="25%">{colTitle1}</th>
								<th width="40%">{colTitle2}</th>
							</tr>
						</thead>
						<tbody>
							{
								summary.map(genes => {
									return (
										<tr key={genes.code}>
											
											<td >{genes.description}</td>
											
											<td>{
												Summaries.renderGeneLinks(genes.unMapped)
												}
											</td>
											<td>{
												Summaries.renderGeneLinks(genes.mapped)
											}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				}
				{Summaries.renderTopandHome()}
			</div>
		);
	}

	toogleDataSeries(e) {
		if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		} else {
			e.dataSeries.visible = true;
		}
		e.chart.render();
	}



	static renderChart(chartData) {


		var mapDataPoints = [],
			identifiedDataPoints = [];

		chartData.forEach(function (p) {
			mapDataPoints.push({ x: new Date(p.year, p.month - 1), y: p.mapped });
			identifiedDataPoints.push({ x: new Date(p.year, p.month - 1), y: p.identified });
		});

		var CanvasJSChart = CanvasJSReact.CanvasJSChart;

		var options = {
			animationEnabled: true,
			theme: "light1",
			zoomEnabled: true,
			zoomType: "x",
			fillOpacity: 100,
			title: {
				text: "Mapped and Identified Retinal Disease Genes",
				fontSize: 20,
				fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
			},
			axisY: {
				title: "",
				valueFormatString: "#0",
				includeZero: true,
				suffix: "",
				prefix: ""
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toogleDataSeries,
				fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
			},
			toolTip: {
				shared: true
			},
			data: [{
				type: "area",
				name: "Mapped",
				markerSize: 5,
				showInLegend: true,
				xValueFormatString: "MMMM-YYYY",
				yValueFormatString: "",
				dataPoints: mapDataPoints
			}, {
				type: "area",
				name: "Identified",
				markerSize: 5,
				showInLegend: true,
				yValueFormatString: "",
				dataPoints: identifiedDataPoints
			}]
		};
		
		return (

			<div className="w-80 element-center">
				<h5 id="d-graph" className="center">D. Graph</h5>
				<CanvasJSChart
					options={options}
					onRef={(ref) => (this.chart = ref)} //Reference to the chart-instance
				/>
				<div className="f-inline-end margin-r-1">
					<a className="align-right" onClick={() => {
						this.chart.exportChart({ format: "jpg" });
					}} alt="Download">
						<i className="bi bi-download"></i> Download
					</a>
				</div>
				<span className="text-small text-italic">** Please ensure that your browser is in 100% zoom for better quality.</span><br/>
				<span className="text-small text-italic">** Highlight an area to zoom in.</span>
				
				<br />
			</div>
		)
	}



	render() {
		let contents = this.state.loading
			? <div className="loader section-center"></div>
			: Summaries.renderSummariesTableA(this.state.summaryDataCount);

		let itemcontents = this.state.itemsLoading
			? <div className="loader section-center"></div>
			: Summaries.renderListedTable(this.state.summaryDataItems, 
				'B. Gene and Locus Symbols by Disease Category (One or More Diseases per Gene/Locus)',
				'b-diseases',
				'Mapped Loci (not Identified)',
				'Mapped and Identified Genes '
			);

		let complexContents = this.state.complexloading
			? <div className="loader section-center"></div>
			: Summaries.renderListedTable(this.state.summaryDataComplex,
				'C. Genes Associated with Complex Forms of Retinal Disease',
				'c-complex',
				'Mapped and Named Loci',
				'Symbols of Associated Genes'
			);

		let progress = this.state.progressLoading
			? <div className="loader section-center"></div> 
			: Summaries.renderChart(this.state.summaryProgress);

	  return (
			<div>
				<div className="App">
					<Helmet>
					  <title>RetNet: Summaries</title>
					</Helmet>
				</div>
			  <NavMenu />
		
			  <h3 align="center">By genes and loci, diseases or graph:</h3>
			  <p align="center">[<a href="summaries#a-genes"> A. Genes and Loci</a> | <a href="summaries#b-diseases">B. Diseases</a> | <a href="summaries#c-complex">C. Complex Diseases</a> | <a href="summaries#d-graph">D. Graph</a>]</p>
			  <p align="center">Tables A and B list different numbers of genes for each disease because they serve different purposes. The purpose of Table A is to provide a simple count of genes, assigning only one disease per gene. Table B is a list of<i>all</i> diseases associated with each gene, sometimes assigning several diseases per gene. Common diseases are listed in Table C but not in Tables A and B. Please see <a href="notes">Notes</a> for details.</p>
			  <hr/>
			  <br/>
			  {contents}
			  <br/>
			  {itemcontents}
			  <br />
			  {complexContents}
			  <br />
			 {progress}
			  {Summaries.renderTopandHome()}
			</div>
		);
	}


	async populateSummariesData() {
		const response = await fetch('/api/summary', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataCount: data, loading: false });
		}
		else {
			alert(data.message);
		}

	}

	async populateSummariesDataByItem() {
		const response = await fetch('/api/summary/getitems', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataItems: data, itemsLoading: false });
		}
		else {
			alert(data.message);
		}
	}

	async getmappingprogress() {
		const response = await fetch('/api/summary/getmappingprogress', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryProgress: data, progressLoading: false });
		}
		else {
			alert(data.message);
		}
	}

	async populateSummariesDataByItemComplex() {
		const response = await fetch('/api/summary/getitems?iscomplex=true', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataComplex: data, complexloading: false });
		}
		else {
			alert(data.message);
		}
	}
}

