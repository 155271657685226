import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet"; 

export class FAQ extends Component {
    static displayName = FAQ.name;

    componentDidMount() {
        document.getElementById(window.location.hash.replace('#', ''))
            ?.scrollIntoView({ behavior: 'instant', block: 'start' });
    }

    render() {
        return (
            <div>
                <div className="App">
                    <Helmet>
                        <title>RetNet: FAQ</title>
                    </Helmet>
                </div>
                <NavMenu />

                <div>
                    <div className="pad-1">
                        <h4 className="border-underline-lgray" id="notes">NOTES</h4>
                       
                        <div className="pad-l-1">
                            <h5>Genes and Mapped Loci Causing Retinal Diseases</h5>
                                <ul>
                                <li>Gene symbols are based on <a href="https://www.genenames.org/" target="_blank">HGNC</a>, <a href="https://www.omim.org/" target="_blank">OMIM</a> or published sources. Unofficial and withdrawn symbols are in parentheses.</li>
                                    <li>The approved HGNC symbol is in <strong>bold</strong>.</li>
                                    <li>Genes and disease loci are listed in chromosomal order from the top (p terminus) to the bottom (q terminus) of each chromosome. If two or more localizations start at the same position, the larger (hence more uncertain) is first; identical localizations are listed in alphabetical order of the HGNC-approved symbol.</li>
                                    <li>Genes within the same chromosomal band are listed in order of the start of mRNA, not alphabetically.</li>
                                </ul>

                            <h5>Summaries of Genes and Loci Causing Retinal Diseases</h5>
                                <ul>
                                    <li>Disease categories are based on common usage, and the categories do not account for diseases which overlap (for example, retinitis pigmentosa and cone-rod dystrophy). That is, these categories are arbitrary to some extent.</li>
                                    <li>For <a href="summaries#a-genes">Table A</a> each disease-causing gene is counted once only. This can be misleading, since different mutations in some genes can cause more than one disease. In these cases, <u>only the first-reported disease is counted</u>. The first-reported disease is usually the most common disease.</li>
                                    <li>For <a href="summaries#b-diseases">Table B</a> all diseases caused by different mutations in a single gene are included. Therefore a gene symbol may appear in more than one category. Table B includes more disease categories (including deafness) than Table A and the number of symbols per category is greater. For example, different mutations in ABCA4 can cause recessive Stargardt disease, recessive MD, recessive RP, recessive fundus flavimaculatus, and recessive cone-rod dystrophy, and may contribute to AMD. As a result, ABCA4 is listed once in Table A but several times in Table B and also in Table C.</li>
                                    <li>Dates for mapped and cloned genes, shown in the <a href="summaries#d-graph">summary graph</a>, are based on the month and year of first publication in a peer-reviewed journal (abstracts are not counted). In cases where a gene was cloned and mapped before its role in retinal disease was known, the date of the first publication reporting a disease association was used for both "mapped" and "cloned" dates.</li>
                                    <li><a href="summaries#c-complex">Table C</a> lists symbols of genes associated with complex forms of retinal disease. The Diseases table includes both 1) genes and loci causing Mendelian diseases and 2) genes only associated with complex diseases. Genes <i>only</i> associated with complex diseases are not included in Tables A and B nor in the graph.</li>
                                </ul>

                            <h5>Symbols of Genes and Loci Causing Retinal Diseases</h5>
                                <ul>
                                    <li>In cases where the same symbol has been used for more than one gene or locus, the official symbol as approved by the HGNC is used. This is a rare occurrence.</li>
                                    <li>The HGNC no longer assigns symbols to phenotypes where the gene remains unidentified and, as of 2019, has withdrawn symbols that fall into this category.</li>
                                </ul>

                            <h5>References</h5>
                                <ul>
                                    <li>Long authorships are truncated to 255 characters with "et al." followed by the final author.</li>
                                </ul>
                        </div>
                    </div>

                    <div className="pad-1">
                        <h4 className="border-underline-lgray" id="abbreviations">ABBREVIATIONS</h4>
                        <div className="pad-l-1">
                            <table className="pad-l-1">
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">AMD/ARMD</td>
                                    <td>age-related macular degeneration</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">BBS</td>
                                    <td>Bardet Biedl syndrome</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">CMT</td>
                                    <td>Charcot-Marie-Tooth disease</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">CNS</td>
                                    <td>central nervous system</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">COD</td>
                                    <td>cone dystrophy</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">CSNB</td>
                                    <td>congenital stationary night blindness</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">ERG</td>
                                    <td>electroretinogram</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">FEVR</td>
                                    <td>familial exudative vitreoretinopathy</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">HGNC</td>
                                    <td>HUGO Gene Nomenclature Committee </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">LCA</td>
                                    <td>Leber congenital amaurosis</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">MD</td>
                                    <td>macular dystrophy or degeneration</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">OMIM</td>
                                    <td>Online Mendelian Inheritance in Man </td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">PXE</td>
                                    <td>pseudoxanthoma elasticum</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">RP</td>
                                    <td>retinitis pigmentosa</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">ROP</td>
                                    <td>retinopathy of prematurity</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">RPE</td>
                                    <td>retinal pigment epithelium</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold pad-r-1 text-end">SNP</td>
                                    <td>single nucleotide polymorphism, a variable DNA nucleotide</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="pad-1">
                        <h4 className="border-underline-lgray" id="faq">FREQUENTLY ASKED QUESTIONS</h4>
                   
                        <ul>
                            <li><strong>Where does the information in RetNet come from?</strong></li>
                            <p>Information in RetNet is from published articles in the scientific literature or is provided by experts on inherited retinal diseases. Gene symbols are usually assigned by the <a href="https://www.genenames.org/" target="_blank">HUGO Gene Nomenclature Committee (HGNC)</a> and are reported in <a href="https://www.omim.org/" target="_blank">OMIM</a>. We strive for accuracy and completeness but we cannot guarantee that all RetNet information is correct or complete. We welcome <a href="contactus">comments and suggestions</a>!</p>

                            <li><strong>How do I cite RetNet in an article?</strong></li>
                            <p>Thanks for asking! The format for citing a Web site in an article depends on the journal or publisher. We request that the URL be included in either the text or references. In the text we suggest either "RetNet, the Retinal Information Network" or "RetNet, https://RetNet.org/". In the references we suggest either "RetNet, https://RetNet.org/" or "SP Daiger, BJF Rossiter, J Greenberg, A Christoffels, W Hide. Data services and software for identifying genes and mutations causing retinal degeneration. Invest. Ophthalmol.Vis. Sci. 39:S295, 1998". Institutional credit should go the University of Texas-Houston Health Science Center.</p>

                            <li><strong>What if I have questions about a particular medical condition?</strong></li>
                            <p>Information on RetNet is for research purposes only, and is intended for the scientific community and other interested individuals. We cannot comment or provide advice on any particular condition. We recommend that personal questions be directed to your physician or health care specialist.</p>

                            <li><strong>How can I support research on inherited retinal diseases?</strong></li>
                            <p>The <strong>Foundation Fighting Blindness</strong> funds innovative and cutting-edge research in promising areas such as genetics, gene and stem-cell therapies, retinal cell transplantation, and pharmaceutical and nutritional therapies. The Foundation’s funding often provides the critical bridge between discovery research, proof of concept, and corporate investment in clinical trials and commercialization. Donations to the <a href="https://donate.fightingblindness.org/donate" target="_blank">Foundation Fighting Blindness</a> support a wide range of research projects on the diagnosis and treatment of inherited retinal diseases. For more ways to get involved with the Foundation visit <a href="http://www.fightingblindness.org/" target="_blank">Foundation Fighting Blindness</a></p>
                        </ul>

                    </div>
            
                </div>
            </div>
        );
    }
}